import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Header from './components/Header';
import Body from './pages/Body';
import Footer from './components/Footer';
import Privacypolicy from './pages/Privacypolicy';
import Salespolicy from './pages/Salespolicy';
import TnC from './pages/TnC';
import AboutEmmjoe from './pages/AboutEmmjoe';
import Agroservices from './pages/Agroservices';
import Kidsdress from './pages/Kidsdress';
import Womensdress from './pages/Womensdress';
import Mensdress from './pages/Mensdress';
import Accessories from './pages/Accessories';
import Shoes from './pages/Shoecollection';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
        <Routes>
          <Route path='/' element={<Body/>}/>
          <Route path='/privacy-policy' element={<Privacypolicy/>}/>
          <Route path='/sales-policy' element={<Salespolicy/>}/>
          <Route path='/tnc' element={<TnC/>}/>
          <Route path='/about-us' element={<AboutEmmjoe/>}/>
          <Route path='/agro-services' element={<Agroservices/>}/>
          <Route path='/kids-dress' element={<Kidsdress/>}/>
          <Route path='/womens-dress' element={<Womensdress/>}/>
          <Route path='/mens-dress' element={<Mensdress/>}/>
          <Route path='/accessories' element={<Accessories/>}/>
          <Route path='/shoes' element={<Shoes/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
