import { Link } from "react-router-dom";
import logo from "../data/EmmJoe2.png";
import original from "../data/original_stamp.png"
import { IoLogoInstagram, IoLogoWhatsapp } from "react-icons/io5";

const Footer=()=>{

    return(
        <div>
        <div className="footer" id="other-resources">
            <div className="footer-section fs1">
                <img id="logo-f" src={logo} alt="EmmJoe Logo"/>
                <span className='footer-emmjoe'><b>Head Office</b></span>
                <span className='footer-emmjoe'>Iseoluwa Plaza, Back of Mandalas, <br/>Lagos Island, Lagos, Nigeria</span>
                <span className='footer-emmjoe'>Phone: +234 802 335 2343</span>
                <span className='footer-emmjoe'>Email: <br/>f.oemmjoeenterprise@gmail.com</span>
            </div>
            <div className="footer-section fs2">
                <br/>
                <br/>
                <span className='footer-emmjoe'><b>Useful Links</b></span>
                <span className='footer-emmjoe' onClick={()=>window.scrollTo(0,0)}><Link to={'/'} className="links">Home</Link></span>
                <span className='footer-emmjoe' onClick={()=>window.scrollTo(0,0)}><Link to={'/agro-services'} className="links">Services</Link></span>
                <span className='footer-emmjoe' onClick={()=>alert("Coming Soon")}>FAQ</span>
                <span className='footer-emmjoe' onClick={()=>alert("Coming Soon")}>Affiliates</span>
            </div>
            <div className="footer-section fs3">
                <br/>
                <br/>
                <span className='footer-emmjoe'><b>About EmmJoe</b></span>
                <span className='footer-emmjoe' onClick={()=>window.scrollTo(0,0)}><Link to={'/about-us'} className="links">About Us</Link></span>
                <span className='footer-emmjoe' onClick={()=>window.scrollTo(0,0)}><Link to={'/privacy-policy'} className="links">Privacy Policy</Link></span>
                <span className='footer-emmjoe' onClick={()=>window.scrollTo(0,0)}><Link to={'/sales-policy'} className="links">Sales Policy</Link></span>
                <span className='footer-emmjoe' onClick={()=>window.scrollTo(0,0)}><Link to={'/tnc'} className="links">Terms and Conditions</Link></span>
            </div>
            <div className="footer-section fs4">
                <span><img src={original} id="original-stamp" alt="Original Stamp"/>
                <span className="percent-original">100% Original Merchandise<br/></span></span>
                <span className="socials-footer" id="socials">
                    <a href="https://wa.me/+2348023352343"><IoLogoWhatsapp className="social"/></a>
                    <a href="https://www.instagram.com/emmjoe_enterprise?igsh=YTQwZjQ0NmI0OA=="><IoLogoInstagram className="social"/></a>
                </span>
            </div>    
        </div>
        <div className="bottom-footer">
            <span>
            Copyright © 2023 F.O. EMMJOE ENTERPRISE. All Rights Reserved
            </span>
            <span> | Designed by <b id='obj-prime'>OBJ-PRIME</b></span>
        </div>
        </div>
    );
}
export default Footer;