import crop1 from "../data/Leafy-Veggies.jpg"
import crop2 from "../data/crop-farming.png"
import livestock1 from "../data/Poultry-Farm.png"
import livestock2 from "../data/pigs.png"
import food_processing1 from "../data/egg-packaging.jpeg"
import food_processing2 from "../data/yam-packaging.jpg"
import consultancy1 from "../data/farm-consultancy.jpg"
import consultancy2 from "../data/tractor2.jpg"

const Agroservices=()=>{
    const handleOrder=()=>{
        window.location= 'mailto:f.oemmjoeenterprise@gmail.com?&Subject=Agro-Allied Services - Emmjoe Enterprise&body= Hello! \nI want to make enquiries about one or more of the Agro-Allied services offered by EmmJoe Enterprise. \nOutlined below are my questions:\n';
    }
    return(
        <div>
            <div align="center">
                <h2 id="agro-head">Farming and Agro-Allied Services</h2>
                <div className="agro-section">
                    <h3>Crop Farming</h3>
                    <div className="agro-img-cont">
                        <img src={crop1} alt="agro-services" className="agro-services-img"/>
                        <img src={crop2} alt="agro-services" className="agro-services-img"/>
                    </div>
                    <p className="agro-desc">
                    Embark on a journey of sustainable agriculture with our Crop Farming services. 
                    F.O. EmmJoe Enterprise is committed to cultivating a diverse array of crops, 
                    employing advanced farming techniques to ensure high yields and superior quality. 
                    From soil preparation to harvesting, we prioritize eco-friendly practices and 
                    precision agriculture to contribute to a healthier and more bountiful future.
                    </p>
                </div><hr/>
                <div className="agro-section">
                    <h3>Livestock Farming</h3>
                    <div className="agro-img-cont">
                        <img src={livestock1} alt="agro-services" className="agro-services-img"/>
                        <img src={livestock2} alt="agro-services" className="agro-services-img"/>
                    </div>
                    <p className="agro-desc">
                    Experience the epitome of ethical and humane Livestock Farming at F.O. EmmJoe Enterprise. 
                    Our commitment to animal welfare and sustainable farming practices ensures that our livestock 
                    is raised in a healthy environment, resulting in premium-quality meat and dairy products. 
                    Join us in promoting responsible agriculture and enjoy the benefits of ethically sourced produce.
                    </p>
                </div><hr/>
                <div className="agro-section">
                    <h3>Produce Processing and Packaging</h3>
                    <div className="agro-img-cont">
                        <img src={food_processing1} alt="agro-services" className="agro-services-img"/>
                        <img src={food_processing2} alt="agro-services" className="agro-services-img"/>
                    </div>
                    <p className="agro-desc">
                    At F.O. EmmJoe Enterprise, we go beyond cultivation to bring you a seamless farm-to-table experience 
                    with our Produce Processing and Packaging services. Our state-of-the-art facilities ensure that 
                    harvested crops are processed and packaged with precision, maintaining freshness and nutritional value. 
                    Discover the convenience of enjoying farm-fresh products with our commitment to quality assurance.
                    </p>
                </div><hr/>
                <div className="agro-section">
                    <h3>Farming Consultancy and Rentage</h3>
                    <div className="agro-img-cont">
                        <img src={consultancy1} alt="agro-services" className="agro-services-img"/>
                        <img src={consultancy2} alt="agro-services" className="agro-services-img"/>
                    </div>
                    <p className="agro-desc">
                    Unlock the full potential of your agricultural endeavors with our Farming Consultancy and Rentage services. 
                    Whether you're a seasoned farmer or a novice, our expert consultants provide tailored guidance on crop selection, 
                    land management, and sustainable practices. Additionally, explore our flexible land rentage options, 
                    allowing you to optimize your farming operations with cost-effective and resource-efficient solutions. 
                    Partner with us for success in every season
                    </p>
                </div>
                <button onClick={handleOrder} className="agro-service-order">Contact EmmJoe Agro-Allied Services</button>
            </div>
        </div>
    );
}
export default Agroservices;