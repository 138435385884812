import { useState, useEffect } from "react"
import shoe1 from "../data/shoe1.png"
import shoe2 from "../data/shoe2.png"
import shoe3 from "../data/shoe3.png"
import shoe4 from "../data/shoe4.png"
import shoe5 from "../data/shoe5.png"
import shoe6 from "../data/shoe6.png"
import shoe7 from "../data/shoe7.png"
import shoe8 from "../data/shoe8.png"


const Shoes=()=>{
    const [itemIndex, setItemIndex] = useState(0);
    const shoeName = [null,
        "Gray Jordan 13",
        "Jordan 1 Vintage",
        "Puma Sneakers",
        "Hiking Sneakers",
        "Crocs",
        "Gold High Heel Sandals",
        "High heels White ",
        "Black Men's Strap Slippers",
    ]
    const handleOrder=()=>{
        window.alert('You are placing an order for ' + shoeName[itemIndex] + '\nClick ok, then click the send Button in the following mail prompt to place your order' );
        window.location= 'mailto:f.oemmjoeenterprise@gmail.com?&Subject=Order for Item - Emmjoe Enterprise&body= Hello! \nThis is an order for '+shoeName[itemIndex];
    }
    useEffect(()=>{
        itemIndex===0?<></>:handleOrder();
    },[itemIndex])

    return(
        <div>
            <div className="body-cont right-corner">
                <div className="item-for-sale">
                    <img className="item-img" src={shoe1} alt="Item for sale"/>
                    <span className="item-name">{shoeName[1]}</span>
                    <span className="item-price"><span className="currency">N</span><span>35,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(1)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={shoe2} alt="Item for sale"/>
                    <span className="item-name">{shoeName[2]}</span>
                    <span className="item-price"><span className="currency">N</span><span>23,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(2)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={shoe3} alt="Item for sale"/>
                    <span className="item-name">{shoeName[3]}</span>
                    <span className="item-price"><span className="currency">N</span><span>26,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(3)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={shoe4} alt="Item for sale"/>
                    <span className="item-name">{shoeName[4]}</span>
                    <span className="item-price"><span className="currency">N</span><span>22,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(4)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={shoe5} alt="Item for sale"/>
                    <span className="item-name">{shoeName[5]}</span>
                    <span className="item-price"><span className="currency">N</span><span>11,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(5)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={shoe6} alt="Item for sale"/>
                    <span className="item-name">{shoeName[6]}</span>
                    <span className="item-price"><span className="currency">N</span><span>13,000</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(6)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={shoe7} alt="Item for sale"/>
                    <span className="item-name">{shoeName[7]}</span>
                    <span className="item-price"><span className="currency">N</span><span>16,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(7)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={shoe8} alt="Item for sale"/>
                    <span className="item-name">{shoeName[8]}</span>
                    <span className="item-price"><span className="currency">N</span><span>8,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(8)}>Place Order</span>
                </div>
                </div>
        </div>
    );
}
export default Shoes;