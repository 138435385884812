const Salespolicy=()=>{

    return(
        <div align='center' >
        <div align='left' className="privacy-policy" id="doc-head">
        <h2>Sales Policy for F.O. EmmJoe Enterprise</h2>

            Effective Date: December 2023<br/><br/>

            Thank you for choosing F.O. EmmJoe Enterprise for your cloth sales, contract and merchandising, farming, and agro-allied service needs. This Sales Policy outlines the terms and conditions that apply to your purchase and engagement with our products and services.

            <h3>1. Ordering Process</h3>
            <b>1.1 Product Information:</b> We strive to provide accurate and up-to-date product information on our website. However, in the event of discrepancies, please contact us for clarification before placing an order.

            <br/><b>1.2 Order Placement:</b> To place an order, you may do so through our website or by contacting our sales team directly. All orders are subject to acceptance by F.O. EmmJoe Enterprise.

            <h3>2. Pricing and Payment</h3>
            <b>2.1 Pricing:</b> Prices for products and services are listed on our website and are subject to change without notice. The price applicable to your order is the one in effect at the time of purchase.

            <br/><b>2.2 Payment:</b> We accept various payment methods, including credit cards and other secure payment gateways. Payment is due at the time of order placement. Orders will be processed once payment is confirmed.

            <h3>3. Order Fulfillment and Delivery</h3>
            <b>3.1 Order Fulfillment:</b> We strive to fulfill orders promptly. However, factors such as product availability and processing times may impact delivery.

            <br/><b>3.2 Delivery:</b> Delivery times are estimated and may vary based on your location. We are not responsible for delays caused by third-party carriers.

           <br/> <b>3.3 Shipping Charges:</b> Shipping charges are calculated based on the weight, dimensions, and destination of the order. These charges will be displayed at the time of checkout.

            <h3>4. Returns and Exchanges</h3>
            <b>4.1 Return Policy:</b> We accept returns for defective or damaged products. Please contact us within Seven days of receiving your order to initiate a return.

            <br/><b>4.2 Exchange Policy:</b> If you receive a defective or damaged product, we will replace it at no additional cost. Exchanges are subject to product availability.

            <h3>5. Customer Support</h3>
            <b>5.1 Contact Information:</b> If you have any questions or concerns about your order, please contact our customer support team at +234 802 335 2343.

            <br/><b>5.2 Feedback:</b> We value your feedback and encourage you to share your experience with our products and services.

            <h3>6. Changes to the Sales Policy</h3>
            We reserve the right to update this Sales Policy at any time. Any changes will be effective immediately upon posting on our website.

            <h3>7. Governing Law</h3>
            This Sales Policy is governed by and construed in accordance with the laws of Nigeria.<br/><br/>

            Thank you for choosing F.O. EmmJoe Enterprise. We appreciate your business and are committed to providing quality products and excellent customer service
        </div>
        </div>
    );
}
export default Salespolicy;