import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../data/EmmJoelogo.jpg";
import {IoGrid, IoCaretDown, IoSearch, IoClose, IoOptionsOutline, IoOptions} from 'react-icons/io5';

const Header=()=>{
    const [optionactive, setOptionactive] = useState(false);// Used to toggle maiin option both on small and big screen 
    const [clothOptionActive, setClothOptionactive] = useState(false);// Used to toggle cloth option when the button is active on a small screen 
    const leaveMsg=()=>{
        window.location= 'mailto:f.oemmjoeenterprise@gmail.com?&Subject=Message for Emmjoe Enterprise&body= Hello EmmJoe Enterprise! \nBelow is my message for you:\n ';
    }
    return(
        <div>
            <nav>
                <div className="logo-cont nav-child"><Link to={"/"} className="links-header"><img id="logo" src={logo} alt="EmmJoe Logo"/></Link></div>
                <div className="options-cont nav-child">{/* Navigation panel for big screen */}
                    <div className="nav-panel pan1"><input type="text" placeholder="Search for Item" id="search"></input>
                    <span><button className="nav-btn"><IoSearch/></button></span>
                    <span>
                        <button className="nav-btn" onClick={()=>setOptionactive((prev)=>!prev)}>{optionactive?<IoClose/>:<IoGrid/>}</button>
                        {optionactive &&
                        <div className="options" onClick={()=>setOptionactive((prev)=>!prev)}>
                            <span><Link to={'/agro-services'} className="links-header">Agro Services</Link></span>
                            <span><a href="#other-resources">Other Resources</a></span>
                            <span><Link to={'/about-us'} className="links-header">About Emmjoe</Link></span>
                            <span><a href="#socials">Connect with Us</a></span>
                            <span onClick={()=>leaveMsg()} className="links-header lvmsg" >Leave a Message</span>
                        </div>}
                    </span>
                    </div>
                    <div className="nav-panel pan2">
                        <span className="nav-top">Merchandising and Contracts</span>
                        <span className="nav-top">Farming and Agro-allied Services<IoCaretDown/></span>
                    </div>
                    <hr/>
                    <div className="nav-panel pan3">
                        <span className="nav-options">
                            Men
                            <Link to={"/mens-dress"} className="links-header">
                            <div className="dropdown-panel">
                                <span>General Wears</span>
                                <span>Traditional </span>
                                <span>Designers</span>
                                <span>Accessories</span>
                            </div>
                            </Link>
                        </span>
                        <span className="nav-options">
                            Women
                            <Link to={"/womens-dress"} className="links-header">
                            <div className="dropdown-panel">
                                <span>Ladies Outfit</span>
                                <span>Specials</span>
                                <span>Traditional </span>
                                <span>Accessories</span>
                            </div>
                            </Link>
                        </span>
                        <span className="nav-options">
                            Kids
                            <div className="dropdown-panel">
                                <span><Link to={'kids-dress'} className="links-header">Kid's Outfit</Link></span>
                            </div>
                        </span>
                        <span className="nav-options">
                            Accessories
                            <Link to={"/accessories"} className="links-header">
                            <div className="dropdown-panel">
                                <span>Wrist Watches</span>
                                <span>Bags</span>
                                <span>Jewelries</span>
                                <span>Head wear</span>
                            </div>
                            </Link>
                        </span>
                        <span className="nav-options">
                            Shoes
                            <div className="dropdown-panel dpr">
                                <span><Link to={'/shoes'} className="links-header">View Shoe Collection</Link></span>
                            </div>
                        </span>
                    </div>
                </div>
                <div className="small-screen-panel">{/* Navigation panel for small screen */}
                    <span>
                        <button className="nav-btn" onClick={()=>{setClothOptionactive((prev)=>!prev); optionactive?setOptionactive(false):<></>}}>{clothOptionActive?<IoOptions/>:<IoOptionsOutline/>}</button>
                        {clothOptionActive &&
                        <div className="options" onClick={()=>setClothOptionactive((prev)=>!prev)}>
                            <span><Link to={"/mens-dress"} className="links-header">Men</Link></span>
                            <span><Link to={"/womens-dress"} className="links-header">Women</Link></span>
                            <span><Link to={'kids-dress'} className="links-header">Kids</Link></span>
                            <span><Link to={"/accessories"} className="links-header">Accessories</Link></span>
                            <span><Link to={'/shoes'} className="links-header">Shoe</Link></span>
                        </div>}
                    </span>
                    <span>
                        <button className="nav-btn" onClick={()=>{setOptionactive((prev)=>!prev); clothOptionActive?setClothOptionactive(false):<></>}}>{optionactive?<IoClose/>:<IoGrid/>}</button>
                        {optionactive &&
                        <div className="options" onClick={()=>setOptionactive((prev)=>!prev)}>
                            <span><Link to={'/agro-services'} className="links-header">Agro Services</Link></span>
                            <span><a href="#other-resources">Other Resources</a></span>
                            <span><Link to={'/about-us'} className="links-header">About Emmjoe</Link></span>
                            <span><a href="#socials">Connect with Us</a></span>
                            <span onClick={()=>leaveMsg()} className="links-header lvmsg">Leave a Message</span>
                        </div>}
                    </span>
                </div>
            </nav>
        </div>
    );
}
export default Header;


