import { useState, useEffect } from "react"
import kids_dress1 from "../data/kids-dress1.png"
import kids_dress2 from "../data/kids-dress2.png"
import kids_dress3 from "../data/kids-dress3.png"
import kids_dress4 from "../data/kids-dress4.png"
import kids_dress5 from "../data/kids-dress5.png"
import kids_dress6 from "../data/kids-dress6.png"
import kids_dress7 from "../data/kids-dress7.png"
import kids_dress8 from "../data/kids-dress8.png"

const Kidsdress=()=>{
    const [itemIndex, setItemIndex] = useState(0);
    const dressName = [null,
                    "Vintage Red Gown",
                    "Kids Polo",
                    "Suspender Pants",
                    "Sweat Shirt and Pants Set",
                    "Kids Gown/ shirt",
                    "Trunk Shirt for Kids",
                    "T-Shirt and Shorts set",
                    "Gown Top"
                    ]//names of clothes

    // This opens an email prompt containing the item name when user clicks place order (Triggered by a useEffect) 
    const handleOrder=()=>{
        window.alert('You are placing an order for ' + dressName[itemIndex] + '\nClick ok, then click the send Button in the following mail prompt to place your order' );
        window.location= 'mailto:f.oemmjoeenterprise@gmail.com?&Subject=Order for Item - Emmjoe Enterprise&body= Hello! \nThis is an order for '+dressName[itemIndex];
    }
    ;
    // Helps to trigger the handle order funstion when user clicks place order and item index is set to the clicked item */}
    useEffect(()=>{
        itemIndex===0?<></>:handleOrder();
    },[itemIndex])

    return(
        <div>
            <div className="body-cont right-corner">
                <div className="item-for-sale">
                    <img className="item-img" src={kids_dress1} alt="Item for sale"/>
                    <span className="item-name">{dressName[1]}</span>
                    <span className="item-price"><span className="currency">N</span><span>5,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(1)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={kids_dress2} alt="Item for sale"/>
                    <span className="item-name">{dressName[2]}</span>
                    <span className="item-price"><span className="currency">N</span><span>2,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(2)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={kids_dress3} alt="Item for sale"/>
                    <span className="item-name">{dressName[3]}</span>
                    <span className="item-price"><span className="currency">N</span><span>3,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(3)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={kids_dress4} alt="Item for sale"/>
                    <span className="item-name">{dressName[4]}</span>
                    <span className="item-price"><span className="currency">N</span><span>7,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(4)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={kids_dress5} alt="Item for sale"/>
                    <span className="item-name">{dressName[5]}</span>
                    <span className="item-price"><span className="currency">N</span><span>4,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(5)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={kids_dress6} alt="Item for sale"/>
                    <span className="item-name">{dressName[6]}</span>
                    <span className="item-price"><span className="currency">N</span><span>3,000</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(6)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={kids_dress7} alt="Item for sale"/>
                    <span className="item-name">{dressName[7]}</span>
                    <span className="item-price"><span className="currency">N</span><span>6,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(7)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={kids_dress8} alt="Item for sale"/>
                    <span className="item-name">{dressName[8]}</span>
                    <span className="item-price"><span className="currency">N</span><span>2,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(8)}>Place Order</span>
                </div>
                </div>
        </div>
    );
}
export default Kidsdress;