import { useState, useEffect } from "react"
import mens_dress1 from "../data/mens-dress1.png"
import mens_dress2 from "../data/mens-dress2.png"
import mens_dress3 from "../data/mens-dress3.png"
import mens_dress4 from "../data/mens-dress4.png"
import mens_dress5 from "../data/mens-dress5.png"
import mens_dress6 from "../data/mens-dress6.png"
import mens_dress7 from "../data/mens-dress7.png"
import mens_dress8 from "../data/mens-dress8.png"

const Mensdress=()=>{
    const [itemIndex, setItemIndex] = useState(0);
    const dressName = [null,
                    "Winter Cargo Pants",
                    "LV Designer Set",
                    "Leather Jacket",
                    "Sweatshirt",
                    "Long Sleeve T-shirt",
                    "T-shirts",
                    "Vintage Tuxedo",
                    "Mens Traditional",
                ]
    const handleOrder=()=>{
        window.alert('You are placing an order for ' + dressName[itemIndex] + '\nClick ok, then click the send Button in the following mail prompt to place your order' );
        window.location= 'mailto:f.oemmjoeenterprise@gmail.com?&Subject=Order for Item - Emmjoe Enterprise&body= Hello! \nThis is an order for '+dressName[itemIndex];
    }
    useEffect(()=>{
        itemIndex===0?<></>:handleOrder();
    },[itemIndex])

    return(
        <div>
            <div className="body-cont right-corner">
                <div className="item-for-sale">
                    <img className="item-img" src={mens_dress1} alt="Item for sale"/>
                    <span className="item-name">{dressName[1]}</span>
                    <span className="item-price"><span className="currency">N</span><span>8,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(1)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={mens_dress2} alt="Item for sale"/>
                    <span className="item-name">{dressName[2]}</span>
                    <span className="item-price"><span className="currency">N</span><span>12,550</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(2)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={mens_dress3} alt="Item for sale"/>
                    <span className="item-name">{dressName[3]}</span>
                    <span className="item-price"><span className="currency">N</span><span>16,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(3)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={mens_dress4} alt="Item for sale"/>
                    <span className="item-name">{dressName[4]}</span>
                    <span className="item-price"><span className="currency">N</span><span>7,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(4)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={mens_dress5} alt="Item for sale"/>
                    <span className="item-name">{dressName[5]}</span>
                    <span className="item-price"><span className="currency">N</span><span>4,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(5)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={mens_dress6} alt="Item for sale"/>
                    <span className="item-name">{dressName[6]}</span>
                    <span className="item-price"><span className="currency">N</span><span>3,000</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(6)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={mens_dress7} alt="Item for sale"/>
                    <span className="item-name">{dressName[7]}</span>
                    <span className="item-price"><span className="currency">N</span><span>40,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(7)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={mens_dress8} alt="Item for sale"/>
                    <span className="item-name">{dressName[8]}</span>
                    <span className="item-price"><span className="currency">N</span><span>12,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(8)}>Place Order</span>
                </div>
                </div>
        </div>
    );
}
export default Mensdress;