import { useState, useEffect } from "react"
import womens_dress1 from "../data/womens-dress1.png"
import womens_dress2 from "../data/womens-dress2.png"
import womens_dress3 from "../data/womens-dress3.png"
import womens_dress4 from "../data/womens-dress4.png"
import womens_dress5 from "../data/womens-dress5.png"
import womens_dress6 from "../data/womens-dress6.png"
import womens_dress7 from "../data/womens-dress7.png"
import womens_dress8 from "../data/womens-dress8.png"

const Womensdress=()=>{
    const [itemIndex, setItemIndex] = useState(0);
    const dressName = [null,
                    "Women's Vintage Cargo Pants",
                    "Cashmere Sweatshirt for Ladies",
                    "Women's Traditional",
                    "Asoebi Gown",
                    "Short Gown",
                    "Designer Crop-top and Mini-Skirt",
                    "Stylish Crop-top",
                    "Wine Red Corporate wear",
                ]
    const handleOrder=()=>{
        window.alert('You are placing an order for ' + dressName[itemIndex] + '\nClick ok, then click the send Button in the following mail prompt to place your order' );
        window.location= 'mailto:f.oemmjoeenterprise@gmail.com?&Subject=Order for Item - Emmjoe Enterprise&body= Hello! \nThis is an order for '+dressName[itemIndex];
    }
    useEffect(()=>{
        itemIndex===0?<></>:handleOrder();
    },[itemIndex])
    return(
        <div>
            <div className="body-cont right-corner">
                <div className="item-for-sale">
                    <img className="item-img" src={womens_dress1} alt="Item for sale"/>
                    <span className="item-name">{dressName[1]}</span>
                    <span className="item-price"><span className="currency">N</span><span>7,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(1)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={womens_dress2} alt="Item for sale"/>
                    <span className="item-name">{dressName[2]}</span>
                    <span className="item-price"><span className="currency">N</span><span>9,000</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(2)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={womens_dress3} alt="Item for sale"/>
                    <span className="item-name">{dressName[3]}</span>
                    <span className="item-price"><span className="currency">N</span><span>26,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(3)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={womens_dress4} alt="Item for sale"/>
                    <span className="item-name">{dressName[4]}</span>
                    <span className="item-price"><span className="currency">N</span><span>17,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(4)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={womens_dress5} alt="Item for sale"/>
                    <span className="item-name">{dressName[5]}</span>
                    <span className="item-price"><span className="currency">N</span><span>10,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(5)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={womens_dress6} alt="Item for sale"/>
                    <span className="item-name">{dressName[6]}</span>
                    <span className="item-price"><span className="currency">N</span><span>16,000</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(6)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={womens_dress7} alt="Item for sale"/>
                    <span className="item-name">{dressName[7]}</span>
                    <span className="item-price"><span className="currency">N</span><span>4,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(7)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={womens_dress8} alt="Item for sale"/>
                    <span className="item-name">{dressName[8]}</span>
                    <span className="item-price"><span className="currency">N</span><span>22,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(8)}>Place Order</span>
                </div>
                </div>
        </div>
    );
}
export default Womensdress;