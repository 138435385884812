import { Link } from 'react-router-dom';
import Slider from 'infinite-react-carousel';
import dress_1a from "../data/dress-1a.png"
import dress_1b from "../data/dress-1b.png"
import dress_2a from "../data/dress-2a.png"
import dress_2b from "../data/dress-2b.png"
import dress_3a from "../data/dress-3a.png"
import dress_3b from "../data/dress-3b.png"
import dress_4a from "../data/dress-4a.png"
import dress_4b from "../data/dress-4b.png"
import dress_5a from "../data/dress-5a.png"
import dress_5b from "../data/dress-5b.png"
import dress_6a from "../data/dress-6a.png"
import dress_6b from "../data/dress-6b.png"
import kids_dress1 from "../data/kids-dress1.png"
import kids_dress2 from "../data/kids-dress2.png"
import kids_dress3 from "../data/kids-dress3.png"
import kids_dress4 from "../data/kids-dress4.png"
import kids_dress5 from "../data/kids-dress5.png"
import kids_dress6 from "../data/kids-dress6.png"
import kids_dress7 from "../data/kids-dress7.png"
import cassava from "../data/cassava.png"
import tractor from "../data/tractor.jpg"
import whatsapp_icon from "../data/whatsapp_icon.png"
import emmjoe_banner from "../data/EmmJoe-banner-red.jpg"

const Body=()=>{

    return(
        <div className="main-body">
            <div className="body-cont left-corner" align='left'>
                <span className="option-list"><b>Top Deals</b></span>
                <span className="option-list">T-shirt</span>
                <span className="option-list">Cargo Pants</span>
                <span className="option-list">Sweat Shirts</span>
                <span className="option-list">Men's Jacket</span>
                <span className="option-list">Men's Corporate</span>
                <span className="option-list">Men's Casual</span>
                <span className="option-list">Men's Traditional</span>
                <span className="option-list">Women's Gown</span>
                <span className="option-list">Women's Cashmere</span>
                <span className="option-list">Women's Casual</span>
                <span className="option-list">Women's Traditional</span>
                <span className="option-list">Designer Dresses</span>
            </div>
            <div className="body-cont right-corner">
            <div className="body-cont right-corner"> {/* Container for the first Six Displayed Items */}
                <Link to={'/mens-dress'} className="links-header">
                <div className="item-for-sale">
                    <Slider dots slidesToShow={1} autoplay="True" arrows="False">
                        <img className="item-img" src={dress_1a} alt="Item for sale"/>
                        <img className="item-img" src={dress_1b} alt="Item for sale"/>
                    </Slider>
                    <span className='extra-description'>Hike like a King</span>
                    <span className="item-name">Winter Cargo Pants</span>
                    <span className="item-price"><span className="currency">N</span><span>8,050</span></span>
                </div>
                </Link>
                <Link to={'/womens-dress'} className="links-header">
                <div className="item-for-sale">
                    <Slider dots slidesToShow={1} autoplay="True" arrows="False">
                        <img className="item-img" src={dress_2a} alt="Item for sale"/>
                        <img className="item-img" src={dress_2b} alt="Item for sale"/>
                    </Slider>
                    <span className='extra-description'>The Modern Cinderella</span>
                    <span className="item-name">Women Vintage Cargo Pants</span>
                    <span className="item-price"><span className="currency">N</span><span>7,500</span></span>
                </div>
                </Link>
                <Link to={'/mens-dress'} className="links-header">
                <div className="item-for-sale">
                    <Slider dots slidesToShow={1} autoplay="True" arrows="False">
                        <img className="item-img" src={dress_3a} alt="Item for sale"/>
                        <img className="item-img" src={dress_3b} alt="Item for sale"/>
                    </Slider>
                    <span className='extra-description'>Look good on LV</span>
                    <span className="item-name">LV Designer</span>
                    <span className="item-price"><span className="currency">N</span><span>12,550</span></span>
                </div>
                </Link>
                <Link to={'/womens-dress'} className="links-header">
                <div className="item-for-sale">
                    <Slider dots slidesToShow={1} autoplay="True" arrows="False">
                        <img className="item-img" src={dress_4a} alt="Item for sale"/>
                        <img className="item-img" src={dress_4b} alt="Item for sale"/>
                    </Slider>
                    <span className='extra-description'>The Alluring Female</span>
                    <span className="item-name">Cashmere Sweat Shirts for Ladies</span>
                    <span className="item-price"><span className="currency">N</span><span>9,000</span></span>
                </div>
                </Link>
                <Link to={'/womens-dress'} className="links-header">
                <div className="item-for-sale">
                    <Slider dots slidesToShow={1} autoplay="True" arrows="False">
                        <img className="item-img" src={dress_5a} alt="Item for sale"/>
                        <img className="item-img" src={dress_5b} alt="Item for sale"/>
                    </Slider>
                    <span className='extra-description'>The Favorite Bride</span>
                    <span className="item-name">Women Traditional Attires</span>
                    <span className="item-price"><span className="currency"></span><span>Varied price</span></span>
                </div>
                </Link>
                <Link to={'/mens-dress'} className="links-header">
                <div className="item-for-sale">
                    <Slider dots slidesToShow={1} autoplay="True" arrows="False">
                        <img className="item-img" src={dress_6a} alt="Item for sale"/>
                        <img className="item-img" src={dress_6b} alt="Item for sale"/>
                    </Slider>
                    <span className='extra-description'>Man on Steeze</span>
                    <span className="item-name">Jacket for Men</span>
                    <span className="item-price"><span className="currency">N</span><span>12,050</span></span>
                </div>
                </Link>
            </div>
            <div className="body-cont right-corner kids-cont">{/* Container for kids dress */}
                <span className='big-theme'><h3>Kid's Booth</h3></span>
                <div>
                    <Slider dots slidesToShow={5} arrows="False">
                        <img className="kids-dress" src={kids_dress1} alt="Item for sale"/>
                        <img className="kids-dress" src={kids_dress2} alt="Item for sale"/>
                        <img className="kids-dress" src={kids_dress3} alt="Item for sale"/>
                        <img className="kids-dress" src={kids_dress4} alt="Item for sale"/>
                        <img className="kids-dress" src={kids_dress5} alt="Item for sale"/>
                        <img className="kids-dress" src={kids_dress6} alt="Item for sale"/>
                        <img className="kids-dress" src={kids_dress7} alt="Item for sale"/>
                    </Slider>
                </div>
                <span className='see-more'><Link to={'kids-dress'} className="links-header">See More</Link></span>
            </div>
            <div className="body-cont right-corner kids-cont">{/* Container for Agro service */}
                <span className='big-theme'><h3>EmmJoe's Agro Services</h3></span>
                <div>
                    <img className="agro-service" src={tractor} alt="Item for sale"/>
                    <img className="agro-service" src={cassava} alt="Item for sale"/>
                </div>
                <span className='see-more'><Link to={'/agro-services'} className="links-header">See More</Link></span>
            </div>
            <img src={emmjoe_banner} className='emmjoe-banner'/>
            <center>
            <div className='whatsapp-cont'>
                <a href="https://wa.me/+2348023352343"><div><img src={whatsapp_icon} className='whatsapp-icon' alt='whatsapp-logo'/></div><div>Chat us up on Whatsapp<br/>__</div></a>
            </div>
            </center>
            </div>
        </div>
    );
}
export default Body;