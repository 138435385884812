
const Privacypolicy=()=>{

    return(
        <div align='center' >
        <div align='left' className="privacy-policy" id="doc-head">
            <h2>Privacy Policy for F.O. EmmJoe Enterprise</h2>

            Effective Date: December 2023<br/>

            Thank you for visiting the website of F.O. EmmJoe Enterprise. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By accessing or using our website, you consent to the practices described in this Privacy Policy.

            <h3>1. Information We Collect</h3>
            <h4>1.1 Personal Information</h4>
            We may collect personal information from you when you visit our website, subscribe to our newsletter, place an order, or interact with us in any other way. This information may include:<br/>

            Name<br/>
            Contact information (email address, phone number, address)<br/>
            Payment details<br/>
            Order history<br/>
            Any other information you voluntarily provide<br/>
            <h4>1.2 Non-personal Information</h4>
            We also collect non-personal information such as IP addresses, browser information, and other technical data that does not personally identify you but is necessary for the functioning and improvement of our website.

            <h3>2. How We Use Your Information</h3>
            We use the collected information for the following purposes:<br/>

            Processing and fulfilling orders<br/>
            Communicating with you about products, services, and promotions<br/>
            Providing customer support<br/>
            Improving and optimizing our website<br/>
            Sending newsletters and marketing communications (if you opt-in)<br/>
            <h3>3. Cookies and Tracking Technologies</h3>
            We use cookies and similar tracking technologies to enhance your experience on our website, analyze trends, administer the website, and gather demographic information about our user base. You can manage your cookie preferences through your browser settings.

            <h3>4. Disclosure of Information</h3>
            We may share your personal information with third parties only in the following circumstances:

            Service providers who help us operate our website and business
            Legal requirements, such as responding to subpoenas or court orders
            Protection of our rights, property, or safety, and that of others
            <h3>5. Security</h3>
            We take reasonable measures to protect the security and confidentiality of your personal information. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.

            <h3>6. Your Choices</h3>
            You have the right to:

            Access, update, or delete your personal information
            Opt-out of receiving marketing communications
            Disable cookies through your browser settings
            <h3>7. Children's Privacy</h3>
            Our website is not directed at individuals under the age of 13. We do not knowingly collect personal information from children.

            <h3>8. Changes to the Privacy Policy</h3>
            We reserve the right to update this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website.

            <h3>9. Contact Us</h3>
            If you have any questions or concerns about this Privacy Policy, please contact us at f.oemmjoeenterprise@gmail.com.

            Thank you for trusting F.O. EmmJoe Enterprise with your information
        </div>
        </div>
    );
}
export default Privacypolicy;