import { useState, useEffect } from "react"
import watch1 from "../data/watch1.png"
import watch2 from "../data/watch2.png"
import watch3 from "../data/watch3.png"
import watch4 from "../data/watch4.png"
import bag1 from "../data/bag1.png"
import bag2 from "../data/bag2.png"
import bag3 from "../data/bag3.png"
import bag4 from "../data/bag4.png"
import jewellery1 from "../data/jewelry1.png"
import jewellery2 from "../data/jewelry2.png"
import jewellery3 from "../data/jewelry3.png"
import jewellery4 from "../data/jewelry4.png"
import cap1 from "../data/cap1.png"
import cap2 from "../data/cap2.png"
import glasses1 from "../data/glasses1.png"
import glasses2 from "../data/glasses2.png"


const Accessories=()=>{
    const [itemIndex, setItemIndex] = useState(0);
    const itemName = [null,
        "Black Leather Stylish Watch",
        "Steel Rolex Watch",
        "Stainless Steel Quartz Wrist Watch",
        "Leather and Gold Vintage Watch",
        "Black Purse Bag",
        "Brown Ladies Bag",
        "Designer Bag Black",
        "Floral Stylish Bag",
        "Pakistani Vintage Jewel Set",
        "Floral Pendant Choker",
        "Floral Necklace",
        "Men's Chain",
        "Stylish Red Face Cap",
        "MD Designer Cap",
        "3pcs Oval Sunglass set",
        "Brown Sunglasses",
    ]
    const handleOrder=()=>{
        window.alert('You are placing an order for ' + itemName[itemIndex] + '\nClick ok, then click the send Button in the following mail prompt to place your order' );
        window.location= 'mailto:f.oemmjoeenterprise@gmail.com?&Subject=Order for Item - Emmjoe Enterprise&body= Hello! \nThis is an order for '+itemName[itemIndex];
    }
    useEffect(()=>{
        itemIndex===0?<></>:handleOrder();
    },[itemIndex])
    return(
        <div>
            <div className="body-cont right-corner">
                <div>
                    <h3 className="accessory-section">Wrist Watches</h3>
                <div className="item-for-sale">
                    <img className="item-img" src={watch1} alt="Item for sale"/>
                    <span className="item-name">{itemName[1]}</span>
                    <span className="item-price"><span className="currency">N</span><span>9,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(1)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={watch2} alt="Item for sale"/>
                    <span className="item-name">{itemName[2]}</span>
                    <span className="item-price"><span className="currency">N</span><span>21,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(2)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={watch3} alt="Item for sale"/>
                    <span className="item-name">{itemName[3]}</span>
                    <span className="item-price"><span className="currency">N</span><span>17,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(3)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={watch4} alt="Item for sale"/>
                    <span className="item-name">{itemName[4]}</span>
                    <span className="item-price"><span className="currency">N</span><span>35,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(4)}>Place Order</span>
                </div>
                </div>
                <div>
                    <h3 className="accessory-section">Bags</h3>
                <div className="item-for-sale">
                    <img className="item-img" src={bag1} alt="Item for sale"/>
                    <span className="item-name">{itemName[5]}</span>
                    <span className="item-price"><span className="currency">N</span><span>4,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(5)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={bag2} alt="Item for sale"/>
                    <span className="item-name">{itemName[6]}</span>
                    <span className="item-price"><span className="currency">N</span><span>3,000</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(6)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={bag3} alt="Item for sale"/>
                    <span className="item-name">{itemName[7]}</span>
                    <span className="item-price"><span className="currency">N</span><span>6,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(7)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={bag4} alt="Item for sale"/>
                    <span className="item-name">{itemName[8]}</span>
                    <span className="item-price"><span className="currency">N</span><span>7,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(8)}>Place Order</span>
                </div>
                </div>
                <div>
                    <h3 className="accessory-section">Jewellery</h3>
                <div className="item-for-sale">
                    <img className="item-img" src={jewellery1} alt="Item for sale"/>
                    <span className="item-name">{itemName[9]}</span>
                    <span className="item-price"><span className="currency">N</span><span>4,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(9)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={jewellery2} alt="Item for sale"/>
                    <span className="item-name">{itemName[10]}</span>
                    <span className="item-price"><span className="currency">N</span><span>3,000</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(10)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={jewellery3} alt="Item for sale"/>
                    <span className="item-name">{itemName[11]}</span>
                    <span className="item-price"><span className="currency">N</span><span>6,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(11)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={jewellery4} alt="Item for sale"/>
                    <span className="item-name">{itemName[12]}</span>
                    <span className="item-price"><span className="currency">N</span><span>7,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(12)}>Place Order</span>
                </div>
                </div>
                <div>
                    <h3 className="accessory-section">Cap and Sunglasses</h3>
                <div className="item-for-sale">
                    <img className="item-img" src={cap1} alt="Item for sale"/>
                    <span className="item-name">{itemName[13]}</span>
                    <span className="item-price"><span className="currency">N</span><span>4,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(13)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={cap2} alt="Item for sale"/>
                    <span className="item-name">{itemName[14]}</span>
                    <span className="item-price"><span className="currency">N</span><span>3,000</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(14)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={glasses1} alt="Item for sale"/>
                    <span className="item-name">{itemName[15]}</span>
                    <span className="item-price"><span className="currency">N</span><span>7,050</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(15)}>Place Order</span>
                </div>
                <div className="item-for-sale">
                    <img className="item-img" src={glasses2} alt="Item for sale"/>
                    <span className="item-name">{itemName[16]}</span>
                    <span className="item-price"><span className="currency">N</span><span>2,500</span></span>
                    <span className="place-order" onClick={()=>setItemIndex(16)}>Place Order</span>
                </div>
                </div>
                </div>
        </div>
    );
}
export default Accessories;