
const TnC=()=>{

    return(
        <div align='center' >
        <div align='left' className="privacy-policy" id="doc-head">
            <h2>Terms and Conditions for F.O. EmmJoe Enterprise</h2>
            
            <p>Effective Date: December 2023</p>

            Thank you for choosing F.O. EmmJoe Enterprise. These Terms and Conditions govern your use of our website and services. By accessing or using our website and services, you agree to comply with and be bound by these terms.

            <h3>1. Acceptance of Terms</h3>
            By using our website or engaging with our services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use our website or services.

            <h3>2. Use of Our Website</h3>
            <h4>2.1 Accuracy of Information:</h4> We strive to provide accurate and up-to-date information on our website. However, we do not guarantee the accuracy, completeness, or reliability of any information.

            <h4>2.2 User Accounts:</h4> If you create an account on our website, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.

            <h3>3. Products and Services</h3>
            <h4>3.1 Product Availability:</h4> Product availability is subject to change without notice. We reserve the right to discontinue any product at any time.

            <h4>3.2 Service Modifications:</h4> We may modify or discontinue our services without notice at any time. We are not liable for any modification, suspension, or discontinuation of our services.

           <h3>4. Pricing and Payments</h3>
            <h4>4.1 Pricing:</h4> Prices for products and services are subject to change without notice. The price applicable to your order is the one in effect at the time of purchase.

            <h4>4.2 Payment:</h4> Payment is due at the time of order placement. We accept various payment methods, and all transactions are processed securely.

            <h3>5. Intellectual Property</h3>
            <h4>5.1 Ownership:</h4> All content on our website, including text, images, logos, and graphics, is the property of F.O. EmmJoe Enterprise and is protected by intellectual property laws.

            <h4>5.2 Use of Content:</h4> You may not reproduce, distribute, or use any content from our website without our express written consent.

            <h3>6. Limitation of Liability</h3>
            <h4>6.1 Disclaimer:</h4> Our website and services are provided "as is" without any warranties, express or implied. We disclaim all warranties of merchantability, fitness for a particular purpose, and non-infringement.

            <h4>6.2 Limitation of Liability:</h4> In no event shall F.O. EmmJoe Enterprise be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our website or services.

            <h3>7. Privacy</h3>
            <h4>7.1 Privacy Policy:</h4> Your use of our website and services is also governed by our Privacy Policy, which is incorporated by reference into these Terms and Conditions.

            <h3>8. Changes to Terms and Conditions</h3>
            We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website.

            <h3>9. Governing Law</h3>
            These Terms and Conditions are governed by and construed in accordance with the laws of Nigeria.

            <p>Thank you for choosing F.O. EmmJoe Enterprise. If you have any questions or concerns about these Terms and Conditions, please contact us at f.oemmjoeenterprise@gmail.com</p>
        </div>
        </div>
    );
}
export default TnC;