import logo from "../data/EmmJoe2.png";

const AboutEmmjoe=()=>{

    return(
        <div align='center' >
        <div align='left' className="privacy-policy" id="doc-head">
            <img id="logo-f" src={logo} alt="EmmJoe Logo"/>
            <h2>About Us - F.O. EmmJoe Enterprise</h2>

            <p>Welcome to F.O. EmmJoe Enterprise - Your Premier Destination for Quality Cloth Sales, Contract and Merchandising, Farming, and Agro-Allied Services!</p>

            <p>At F.O. EmmJoe Enterprise, we believe in more than just transactions; we believe in building lasting relationships with our customers by offering a diverse range of products and services tailored to meet your unique needs.</p>

            <h3>Our Mission</h3>
            Our mission is to be a trusted and innovative partner for individuals and businesses alike, providing top-notch cloth sales, comprehensive contract and merchandising solutions, and sustainable farming and agro-allied services. We are committed to delivering excellence in every aspect of our operations.

            <h3>What Sets Us Apart</h3>
            <b>1. Quality Products:</b> Our cloth sales feature a curated collection of high-quality fabrics and trendy designs, ensuring that you not only look good but feel good too.<br/><br/>

            <b>2. Comprehensive Solutions:</b> Whether you're seeking contract and merchandising services or engaging in agro-allied activities, we offer comprehensive solutions that exceed expectations.<br/><br/>

            <b>3. Sustainability:</b> We prioritize sustainable and environmentally friendly practices in our farming and agro-allied services, contributing to a greener and healthier planet.<br/><br/>

            <b>4. Customer-Centric Approach:</b> Your satisfaction is our priority. Our dedicated team is always ready to assist you, providing personalized attention and ensuring a seamless experience.<br/><br/>

            <h3> Our Story</h3>
            Founded 2010, F.O. EmmJoe Enterprise has evolved from a small venture into a dynamic and customer-focused enterprise. The journey has been marked by a commitment to integrity, quality, and continuous improvement. Today, we stand proud as a reliable source for all your cloth and agricultural needs.

            <h3>Our Team</h3>
            Behind every successful enterprise is a dedicated team. Our diverse team brings a wealth of experience and expertise, working collaboratively to drive innovation and excellence across our operations.

            <h3>Get in Touch</h3>
            We invite you to explore our website and discover the world of F.O. EmmJoe Enterprise. Whether you're in search of fashionable fabrics, professional contract and merchandising services, or sustainable farming solutions, we have you covered.<br/><br/>

            Thank you for considering F.O. EmmJoe Enterprise as your trusted partner. We look forward to serving you and building a lasting relationship based on trust, quality, and shared success.
        </div>
        </div>
    );
}
export default AboutEmmjoe;